/* index.css */
:root {
  /* Light Mode Variablen */
  --background-color: #ffffff;
  /* Beispiel: weißer Hintergrund im Light Mode */
  --sidebar-color: #F7F7F7;
  --text-color: #0c0c0c;
  /* Beispiel: schwarzer Text im Light Mode */
  --primary-color: #E7E7E7;
  /* Beispiel: Primärfarbe */
  --content-padding: 24px;
  --message-user-background: rgba(255, 255, 255, 0.03);
  --message-robot-background: rgba(255, 255, 255, 0.1);
  --message-text-color: #474343;
  --input-border-color: #E7E7E7;
  --input-border-color-light: rgba(0, 0, 0, 0, 0.3);
  /* Dunkler Rahmen für Light Mode */
  --input-text-color: #000;
  /* Dunkle Schrift für Light Mode */
  --input-background-color: transparent;
  /* Transparenter Hintergrund */
  --input-focus-border-color: #d8d8d8;
  --placeholder-color: rgba(0, 0, 0, 0.5);
  --image-invert: invert(1);
  --scrollbar-color: #E7E7E7;
  --icon-color-primary: #16aa99;
  --icon-color-secondary: #E7E7E7;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark Mode Variablen */
    --background-color: #212121;
    /* Beispiel: dunkler Hintergrund im Dark Mode */
    --sidebar-color: #171717;
    --text-color: #ffffff;
    /* Beispiel: weißer Text im Dark Mode */
    --primary-color: #ffffff13;
    /* Beispiel: Primärfarbe im Dark Mode */
    --input-border-color: #ffffff77;
    --input-border-color-light: #ffffff30;
    /* Heller Rahmen für Dark Mode */
    --input-text-color: #fff;
    /* Helle Schrift für Dark Mode */
    --input-focus-border-color: #bb86fc;
    --placeholder-color: rgba(255, 255, 255, 0.5);
    --message-text-color: #ffffff;
    --image-invert: invert(0);
    --scrollbar-color: rgba(255, 255, 255, 0.2);
  }
}

.ant-spin-dot {
  width: 29px !important;
  height: 22px;
}

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}

.chatInput,
.chatInput,
.chatInput:focus {
  background-color: transparent !important;
  /* Stellt den Hintergrund transparent ein */
  border-color: var(--input-border-color) !important;
  /* Verwendet die benutzerdefinierte Rahmenfarbe */
  border-width: 1px;
  padding-right: 70px !important;
  border-radius: 20px !important;
}


.chatInput::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input::placeholder,
.ant-input::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input:hover::placeholder,
.ant-input:hover::placeholder {
  color: var(--placeholder-color) !important;
}

.ant-input-affix-wrapper .ant-input:focus::placeholder,
.ant-input:focus::placeholder {
  color: var(--placeholder-color) !important;
}

/* Optional: Überschreibe den Rahmenfarbenwechsel beim Hover */
.ant-input:hover {
  border-color: var(--input-focus-border-color) !important;
  /* Ändere die Rahmenfarbe bei Hover */
  background-color: transparent !important;
}

.appLayout,
.innerLayout,
.contentLayout {
  background-color: var(--background-color);
}

.contentLayout {
  height: 100vh;
  margin: auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: var(--content-padding) var(--content-padding) 0 var(--content-padding);
}

.chatInputContainer {
  padding: 0px var(--content-padding) var(--content-padding) var(--content-padding);
}

.chatForm {
  position: relative;
}

/* Stil für ChatInput */
.chatInput {
  flex: 1;
  margin-right: 8px;
  border: 1px solid var(--input-border-color);
  /* Nutze die Variable für die Rahmenfarbe */
  background: var(--input-background-color);
  /* Stelle sicher, dass der Hintergrund transparent ist */
  color: var(--input-text-color);
  /* Nutze die Variable für die Textfarbe */
  padding: 15px;
  border-radius: 20px;
  outline: none;
  /* Entferne den Standard-Fokus-Rahmen */
}

.chatInput:focus {
  border-color: var(--input-focus-border-color);
  /* Ändere die Rahmenfarbe bei Fokus */
}

.chatButton {
  border: none;
  background: var(--button-background-color);
  color: var(--button-text-color);
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 !important;
}

.chatIcon {
  color: var(--button-text-color);
}

#addThreadButton,
#addThreadButton:hover {
  border: 0px solid var(--input-border-color) !important;
  /* Nutze die Variable für die Rahmenfarbe */
  background: var(--sidebar-color) !important;
  /* Stelle sicher, dass der Hintergrund transparent ist */
  color: var(--input-text-color) !important;
  /* Nutze die Variable für die Textfarbe */
  height: 38px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 4px 24px !important;
}



.messageListItem {
  padding: 0;
  border: none;
  background-color: transparent;
}

.messageContentUser,
.messageContentRobot {
  display: flex;
  align-items: center;
  color: var(--message-text-color);
  padding: 20px;
  border-radius: 20px;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 15px;
  width: 100%;
  align-items: flex-start;
  gap: 14px;
}

.chatIconAssistant,
.chatIconUser {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chatIconAssistant {
  background: var(--icon-color-primary) !important;
}

.chatIconUser {
  background: var(--icon-color-secondary);
}

.messageContentBody {
  display: flex;
  justify-content: center;
  min-height: 30px;
  flex-direction: column;
}

.messageContentBody ol {
  list-style: none;
  list-style-position: outside;
  counter-reset: item;
  padding-inline-start: 0px;
}

.messageContentBody ul {
  padding-inline-start: 0px;
}

.messageContentBody ol li {
  counter-increment: chapter;
  margin-bottom: 5px;
  position: relative;
}

.messageContentBody ol ol,
.messageContentBody ol ul,
.messageContentBody ul ol, 
.messageContentBody ul ul { 
  padding-left: 20px; 
}





.messageContentBody ol > li:before {
  margin-right: 10px;
  position: absolute;
  content: counter(item);
  opacity: 0.5;
  left: -20px;
}

.messageContentBody code {
  font-size: 12px !important;
  background: black;
  padding: 5px 10px;
  border-radius: 5px;
  color: lime;
  margin: 5px;
}


.messageContentBody>*:not(:last-child) {
  margin-bottom: 20px;
}

.messageContentUser {
  background: var(--message-user-background);
  align-self: flex-end;
  margin-bottom: 0;
}

.messageContentRobot {
  background: var(--message-robot-background);
  align-self: flex-start;
  margin-bottom: 0;
}

.messageContentSystem {
  padding: 0px 34px;
  color: var(--text-color) !important;
}

.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.threadListContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 58px); /* Ersetzen Sie [HeaderHöhe] durch die tatsächliche Höhe Ihres Headers */
  overflow: hidden;
}

.threadsList {
  flex: 1;
  overflow-y: auto;
  border: none !important;
}


.threadsList {
  background: transparent !important;
}

.threadsList .ant-menu-item {
  margin: 0;
  width: 100%;
}

.custom_form {
  width: 100%;
}


.sidebar {
  background-color: var(--sidebar-color) !important;
  padding: 5px;
  width: 250px !important;
  max-width: 250px !important;
  flex: 0 0 250px !important;
}

.sidebar .ant-menu-item {
  color: var(--text-color) !important;
}

.sidebar .ant-menu-item-selected {
  background-color: var(--primary-color) !important;
}


* {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  /* Halbtransparentes Weiß */
  border-radius: 2px;
}

/* Stil für den Scrollbar-Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  /* Transparenter Hintergrund */
}

/* Stil für den gesamten Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}

/* Versteckt die Scrollbar, ermöglicht aber das Scrollen in WebKit-Browsern */
.ant-input-textarea > textarea::-webkit-scrollbar {
  display: none !important;
}

/* Versteckt die Scrollbar in Firefox */
.ant-input-textarea > textarea {
  scrollbar-width: none !important;
}

/* Verhindert Scrollbar-Blitzen in MS Edge */
.ant-input-textarea > textarea {
  -ms-overflow-style: none !important;
}


/* Form Styling */

/* Anpassungen für Ant Design Formularelemente */

/* Stil für Input und Textarea */
.ant-input,
.ant-input-affix-wrapper,
.ant-input-textarea, .supabase-auth-ui_ui-input {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--input-text-color) !important;
}

.ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-textarea:focus,
.ant-input-focused,
.ant-input-affix-wrapper-focused,
.ant-input-textarea-focused, .supabase-auth-ui_ui-input:focus {
  box-shadow: none !important;
  border-color: var(--input-focus-border-color) !important;
}

/* Stil für Select */
.ant-select-selector {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--input-text-color) !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-open .ant-select-selector {
  border-color: var(--input-focus-border-color) !important;
  box-shadow: none !important;
  color: var(--input-text-color) !important;
}

.ant-select-focused .ant-select-selection-item {
  color: var(--input-text-color) !important;
}

/* Stil für Select Dropdown */
.ant-select-dropdown {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

/* Stil für Optionen im Select Dropdown */
.ant-select-item {
  color: var(--text-color) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--primary-color) !important;
}

/* Stil für den Hover-Effekt von Optionen */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--input-focus-border-color) !important;
}

/* Stil für Buttons */
.ant-btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}

.ant-btn:hover,
.ant-btn:focus {
  background-color: var(--input-focus-border-color) !important;
  border-color: var(--input-focus-border-color) !important;
}

/* Anpassung für Fehlermeldungen */
.ant-form-item-explain,
.ant-form-item-extra {
  color: var(--text-color) !important;
}

/* Optional: Stil für den Formularcontainer */
.custom_form {
  padding: var(--content-padding);
  background-color: var(--sidebar-color);
  border-radius: 20px;
}

.custom_form label {
  color: var(--text-color) !important;
  margin-top: 10px !important;
}

/* Erhöhtes Padding für Input und Textarea */
.ant-input,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input-textarea {
  padding: 12px 15px;
  /* Erhöhen Sie diese Werte entsprechend Ihren Bedürfnissen */
}

/* Spezifische Anpassung für Textarea, falls gewünscht */
.ant-input-textarea textarea {
  padding: 12px 15px;
  /* Konsistentes Padding innerhalb der Textarea */
}

/* Anpassung für Select, um das Padding zu erhöhen */
.ant-select-selector {
  padding: 25px 15px !important;
  /* Erhöht das Padding, um mehr Platz im Inneren zu schaffen */
}

/* Anpassungen für die Höhe der Select-Komponente, um sie an das erhöhte Padding anzupassen */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: initial;
  /* Anpassung der Zeilenhöhe, um mit dem neuen Padding übereinzustimmen */
}

/* Erhöhte Abstände zwischen Formularfeldern, um eine bessere visuelle Trennung zu gewährleisten */
.ant-form-item {
  margin-bottom: 24px;
  /* Erhöhen Sie den Abstand zwischen den Formularelementen */
}

/* Sicherstellen, dass alle Texte in Ant Design Komponenten die richtige Farbe haben */
.ant-form,
.ant-form-item-label,
.ant-form-item-control-input-content {
  color: var(--text-color) !important;
}

.form-submit-button {
  height: 40px;
  margin-top: 10px;
}

.form-submit-button {
  height: 32px;
}

.form-notification {
  padding: 24px;
  border-radius: 20px;
  background-color: lightgreen;
  width: 100%
}

.chatButton {
  height: 32px !important;
}

.auth-wrapper {
  min-height: 100vh;
  background-color: var(--background-color);
  display: flex;
}

.auth-form {
  max-width: 80vw;
  width: 400px;
  margin: auto;
  padding-bottom: 10vh;
}

.auth-form * {
  font-family: 'Source Sans Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

.auth-title {
  color: var(--text-color) !important;
  font-size: 2rem !important;
}

.chatButton:disabled {
  opacity: 0.5;
}

.ant-collapse, .ant-collapse-item {
  border: none !important;
  color: var(--text-color) !important;
}

.ant-collapse {
  padding-left: 40px;
}

.ant-collapse-header-text, .messageContentBody  {
  color: var(--text-color) !important;
}

.ant-collapse-content-box .messageContentBody  {
  padding-left: 30px;
}

.ant-collapse-expand-icon svg {
  fill: var(--text-color) !important;
}

.ant-collapse-content {
  background: none !important;
  border: none !important;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Passen Sie die Höhe nach Bedarf an */
  color: white; /* Stellt die Textfarbe für den gesamten Container ein */
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataText {
  color: white; /* Stellt die Textfarbe speziell für den Text ein, falls benötigt */
  text-align: center; /* Zentriert den Text */
  font-size: 1.5rem !important; /* Größere Schriftgröße, passen Sie nach Bedarf an */
}

.supabase-auth-ui_ui-button {
  
}

/* Grundlegende Stilisierung für Markdown-Tabellen */
.messageContentBody table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  box-shadow: 0 0 5px rgba(0,0,0,0.1); /* Leichter Schatten für die Tabelle */
}

.messageContentBody th,
.messageContentBody td {
  border: 1px solid #ddd; /* Rahmenfarbe */
  text-align: left;
  padding: 8px; /* Abstand innerhalb der Zellen */
}

.messageContentBody th {
  white-space: nowrap;
}

.downloadItem {
  padding: 20px !important;
  background: var(--sidebar-color) !important;
  border-radius: 20px;
}

.downloadItem * {
  color: var(--text-color) !important;
}

.downloadItem a {
  padding: 10px 20px !important;
    height: unset;
}

.messageList {
  padding-bottom: 20px;
}

/* Stil für direkte li-Kinder von ol */
.messageContentBody > ol > li:before {
  content: counter(item) ".";
  position: absolute;
  left: -20px;
  opacity: 0.5;
}

#message {
  height: 109px;
    min-height: 59px;
    max-height: 184px;
    padding: 16px;
}
/* Initialisiert den Zähler für ol und setzt das Styling zurück für ul in ol */
.messageContentBody > ol {
  counter-reset: item;
}

.messageContentBody > ol > li {
  counter-increment: item;
  position: relative;
}

/* Entfernt spezifisches :before-Styling für ul in ol */
.messageContentBody > ol > ul, .messageContentBody > ol > ul li:before {
  content: normal;
}

/* Modal Header */
.ant-modal-header, .ant-modal-content {
  background-color: var(--sidebar-color) !important;
  color: var(--text-color) !important;
}

/* Modal Title */
.ant-modal-title {
  color: var(--text-color) !important;
}

/* Modal Close Button */
.ant-modal-close-x {
  color: var(--text-color);
}

/* Modal Body */
.ant-modal-body {
  color: var(--text-color);
}

/* Formularfelder und Labels im Modal */
.ant-modal .ant-form-item-label > label {
  color: var(--text-color);
}

.ant-modal .ant-input,
.ant-modal .ant-input-affix-wrapper,
.ant-modal .ant-input-textarea {
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  border-color: var(--input-border-color);
}

.ant-modal .ant-input:focus,
.ant-modal .ant-input-affix-wrapper:focus,
.ant-modal .ant-input-textarea:focus {
  border-color: var(--input-focus-border-color);
  box-shadow: none;
}

/* Anpassung der Schaltflächen im Modal */
.ant-modal .ant-btn {
  background-color: var(--primary-color);
  color: var(--text-color);
  border-color: var(--primary-color);
}

.ant-modal .ant-btn:hover,
.ant-modal .ant-btn:focus {
  background-color: var(--input-focus-border-color);
  border-color: var(--input-focus-border-color);
}

.ant-form-item-control-input-content button {
  height: 40px;
}

.UserInfoButton {
  width: 100%;
  height: 50px;
  text-align: left;
  padding-left: 23px;
  border: none;
  background: none !important;
}

.UserInfoButton:hover {
  background: none !important;
}

.disclaimer {color: var(--text-color); text-align: center; margin-top: 5px; font-size: 12px !important; opacity: 0.6;}


.assistantSettingsContainer {
  position: fixed;
    top: 0;
    right: 0;
    width: calc(100vw - 250px);
    height: 100vh;
    background-color: var(--background-color);
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}

.settingsList {
  flex: 1;
  overflow-y: auto; /* Bei vielen Einträgen scrollbar machen */
}

.settingsEditor {
  flex: 2;
  padding: 20px;
}

.SettingsButton {
  z-index: 901;
  position: absolute !important;
  background: none !important;
  right: 15px;
  top: 10px;
}

.SettingsButton:hover {
  background: none !important;
}

.addPrompt button {
  width: 50px !important;
  height: 50px;
}

.assistantSettingsForm {
  width: 700px;
}

.addPromptButton {
  position: absolute;
    width: 38px !important;
    height: 38px;
    top: 6px;
    right: 6px;
}

.optigptTopLogo {
  filter: var(--image-invert);
}

.dateHeader .ant-menu-title-content {
  font-size: 12px !important;
  line-height: 1 !important;
  opacity: 0.6;
}

.dateHeader  {
  height: 40px !important;
  padding-top: 15px;
  font-weight: bold;
  opacity: 0.6;
}

.noDataText {
  color: var(--text-color);
}